const AboutMe = () => {
  return (
    <section>
      <h2 className="title-font pink-text h2-tag">About Me</h2>
      <p className="white-text p-tag">
        I am a front-end web developer with <strong> 7 years </strong>of working
        experience. I combine my management expertise with technical skills and
        fully commit to consistency, meeting deadlines, and utilizing all
        available resources to achieve results. I enjoy creating beautiful,
        accessible, and responsive websites using React, HTML, CSS, Tailwind,
        Sass, SCSS, Typescript, and Storybook. Effective use of time,
        problem-solving, and communication skills help me show a positive
        attitude in complex situations, and my desire to learn quickly adapts me
        to changing situations.
      </p>
    </section>
  );
};
export default AboutMe;

import AboutPreview from './AboutPreview';
import HeaderPreview from './HeaderPreview';
import PortfolioPreview from './PortfolioPreview';
import ContactPreview from './ContactPreview';
import { Footer } from '../../components';
import ScrollButton from '../../components/ScrollButton';
import React from 'react';

const Mainpage = () => {
  document.title = 'Personal Portfolio | Abel Adane';
  return (
    <article className="main-page">
      <HeaderPreview />
      <AboutPreview />
      <PortfolioPreview />
      <ContactPreview />
      <ScrollButton />
      {/* <Footer /> */}
    </article>
  );
};

export default Mainpage;

import Presence_2 from '../assets/images/portfolio/presence_2.gif';
import MESIRAT from '../assets/images/portfolio/mesirat.png';
import GEBEYA from '../assets/images/portfolio/gebeya.png';
import EPP from '../assets/images/portfolio/epp.png';
import HRMS from '../assets/images/portfolio/hrms.jpg';
import IFMS from '../assets/images/portfolio/ifms.webp';
import CANVAS from '../assets/images/portfolio/canvas.jpg';
import VRGALLARY from '../assets/images/portfolio/vrgallery.jpg';
import LMS from '../assets/images/portfolio/lms.png';
import DMS from '../assets/images/portfolio/dms.webp';
import SAFEZONE from '../assets/images/portfolio/safezone.png';
import DSPACE from '../assets/images/portfolio/dspace.png';
import Library from '../assets/images/portfolio/library.png';
import Webinar from '../assets/images/portfolio/webinar.png';

const ProjectsPreviewData = [
  {
    id: 'gebeya',
    img: GEBEYA,
    name: 'Gebeya',
    stack: [
      '< Angular >',
      '< PrimeNG />',
      '< PrimeFlex />',
      '< NestJS />',
      '< Postgres />',
      '< AWS />',
      '< Gitlab />',
    ],
    src: 'https://www.gebeya.com/',
    source: '',
    description:
      'Hire freelancers or permanent staff from our talent pool to work remotely or on-site. Gebeya Inc. is a Pan-African online Freelance Talent Marketplace headquartered in Addis Ababa, Ethiopia. The company aims to become an active contributor to Africa’s digital transformation and build a booming Africa through leveraging continental and global job opportunities for African talent. Gebeya’s network of highly skilled, certified, and multilingual African talent on a digital platform services clients across the globe. Gebeya’s vision is of an Africa equipped with a competitive skilled workforce, and a greater number of innovative startups that will drive digital transformation.				',
  },
  {
    id: 'Mesirat',
    img: MESIRAT,
    name: 'Mesirat',
    stack: [
      '< Angular >',
      '< MicroFrontend/>',
      '< single-spa />',
      '< PrimeNG />',
      '< PrimeFlex />',
      '< NestJs />',
      '< Postgres />',
      '< AWS />',
      '< Bitbucket />',
    ],
    src: 'https://www.mesirat.org/',
    source: '',
    description:
      'Mesirat is a 5 year, multi-phase program to create opportunities for 1 million highly skilled workers through creating, supporting, and nurturing 100 multi-sided gig and professional marketplaces (GPMs) in Ethiopia.',
  },
  {
    id: 'presence',
    img: Presence_2,
    name: 'Presence',
    stack: [
      '< React.js />',
      '< Ant Design />',
      '< TailwindCSS />',
      '< Redux />',
      '< NestJs />',
      '< Nx Dev />',
      '< Postgres />',
      '< AWS />',
      '< Bitbucket />',
    ],
    src: 'https://www.presencebrowser.com/',
    source: '',
    description:
      'A  SaaS platform that is scalable and maintained. The platform contains a chrome extension, an SDK, and a web app to challenge users to compete using the data submitted from the extension. The chrome extension enables you to annotate pieces of text on any website and build relationships among those annotations. The SDK allows you to bring the same functionality of the extension into your web app. In addition to that, the SDK allows you to have real-time user interaction on your website and users can chat and toggle their visibility per page! It has a single-sign-on feature for managing accounts through all the web apps and the chrome extension.',
  },
  {
    id: 'EPP',
    img: EPP,
    name: 'Enterprise Portfolio Project',
    stack: [
      '< Angular />',
      '< Ant Design />',
      '< TailwindCSS />',
      '< NGXS />',
      '< NestJS />',
      '< Nx Dev />',
      '< Postgres />',
      '< AWS />',
      '< Bitbucket />',
    ],
    src: 'https://excellerentsolutions.com',
    source: '',
    description: 'A resource management platform for Enterprise organizations.',
  },
  {
    id: 'HRMS',
    img: HRMS,
    name: 'Human Resource Management System',
    stack: [
      '< Angular />',
      '< Sencha />',
      '< TailwindCSS />',
      '< NGXS />',
      '< Dotnet />',
      '< MSSQL />',
      '< Github />',
    ],
    src: '',
    source: '',
    description:
      'Human resources management system, is a suite of software applications used to manage human resources and related processes throughout the employee lifecycle. An HRMS enables a company to fully understand its workforce while staying compliant with changing tax laws and labor regulations.',
  },
  {
    id: 'IFMS',
    img: IFMS,
    name: 'Integrated Financial Management System.',
    stack: [
      '< Angular />',
      '< Sencha />',
      '< TailwindCSS />',
      '< NGXS />',
      '< Dotnet />',
      '< MSSQL />',
      '< Github />',
    ],
    src: '',
    source: '',
    description:
      'Budget preparation and execution to accounting and reporting integrates with other information systems, such as human resources, payroll & pension, e-procurement and revenue (tax and customs).',
  },
  {
    id: 'VRGALLARY',
    img: VRGALLARY,
    name: 'VR Gallary',
    stack: [
      '< Unit3D />',
      '< C# />',
      '< Postgres />',
      '< NodeJs />',
      '< Blender />',
      '< Photoshop />',
      '< Github />',
    ],
    src: '',
    source: '',
    description:
      'View, pick and shop furnitures using an Oculus GO VR Headset ',
  },
  {
    id: 'Canvas',
    img: CANVAS,
    name: 'Canvas',
    stack: [
      '< Unit3D />',
      '< C# />',
      '< Postgres />',
      '< NodeJs />',
      '< Blender />',
      '< Photoshop />',
      '< Github />',
    ],
    src: '',
    source: '',
    description: 'View, walk around, and pick arts (paintings)',
  },
  {
    id: 'LMS',
    img: LMS,
    name: 'LMS',
    stack: [
      '< PHP />',
      '< JS />',
      '< Postgres />',
      '< Dialog Flow />',
      '< Github />',
    ],
    src: 'http://lms.smuc.edu.et/',
    source: '',
    description:
      'Students can submit incorrectly assigned courses, request for grade, request for university department contact infos.',
  },
  {
    id: 'DMS',
    img: DMS,
    name: 'Document Management System',
    stack: ['< PHP />', '< HTML />', '< Mysql />', '< JS />', '< Github />'],
    src: '',
    source: '',
    description:
      'Used to receive, track, manage and store documents and reduce paper. Most are capable of keeping a record of the various versions created and modified by different users (history tracking).',
  },
  {
    id: 'safezone',
    img: SAFEZONE,
    name: 'SafeZone',
    stack: ['< C# />', '< Mysql />', '< Github />'],
    src: '',
    source: '',
    description:
      'Used to filter document file types and block social media during working hours.',
  },
  {
    id: 'Dspace',
    img: DSPACE,
    name: "St. Mary's University DSPACE",
    stack: [],
    src: 'http://repository.smuc.edu.et/',
    source: '',
    description:
      'St. Mary’s University, Ethiopia, Institutional Repository is an open access institutional archive that collects, preserves and distributes research outputs produced at the University. These include journal articles, conference proceedings, books, bulletins, newsletters and manuals.',
  },
  {
    id: 'Library',
    img: Library,
    name: "St. Mary's University Library",
    stack: [],
    src: 'https://web.archive.org/web/20220615232234/http://library.smuc.edu.et/',
    source: '',
    description:
      'Koha is a full-featured open-source ILS. Developed initially in New Zealand by Katipo Communications Ltd and first deployed in January of 2000 for Horowhenua Library Trust, Koha is currently maintained by a team of software providers and library technology staff from around the globe.',
  },
  {
    id: 'webinar',
    img: Webinar,
    name: "St. Mary's University Webinar",
    stack: ['< bbb (big blue button) />'],
    src: 'https://web.archive.org/web/20170913153601/http://webinar.smuc.edu.et:80/',
    source: '',
    description:
      'Slideshow presentations – where images are presented to the audience and markup tools and a remote mouse pointer are used to engage the audience while the host discusses slide content.    Live or streaming video – where full motion webcam, digital video camera or multi-media files are pushed to the audience. VoIP – Real time audio communication through the computer via use of headphones and speakers. Web tours – where URLs, data from forms, cookies, scripts and session data can be pushed to other participants enabling them to be pushed though web-based logons, clicks, etc. This type of feature works well when demonstrating websites where users themselves can also participate. Meeting Recording – where presentation activity is recorded on the client side or server side for later viewing and/or distribution. Whiteboard with annotation (allowing the host and/or attendees to highlight or mark items on the slide presentation. Or, simply make notes on a blank whiteboard.) Text Chat- For live question and answer sessions, limited to the people connected to the meeting. Text chat may be public (echoed to all participants) or private (between 2 participants). Polls and surveys (allows the host to conduct questions with multiple choice answers directed to the audience) Screen sharing/desktop sharing/application sharing (where participants can view anything the host currently has shown on their screen. Some screen sharing applications allow for remote desktop control, allowing participants to manipulate the hosts screen, although this is not widely used.)',
  },
];
export { ProjectsPreviewData };

import { Link } from "react-router-dom";

const HeaderPreview = () => {
  return (
    <article className="header h-screen">
      <h1 className="header-font white-text h1-tag">
        Hi, I'm <br></br>
        <em className="pink-text">Abel Adane</em>
        <br></br>Senior Software Developer
      </h1>
      <p className="gray-text p-tag mt-10">Front End Development</p>
      <Link className="pink-text mt-10" to="/portfolio">
        Check my work
      </Link>
    </article>
  );
};

export default HeaderPreview;

import React from 'react';
import ExternalLink from '../../assets/images/project-external.png';
import GithubRepo from '../../assets/images/project-source.png';
import { ProjectsPreviewData } from '../../data/ProjectsData';

const Projects = () => {
  return (
    <section className="portfolio-grid space-y-4">
      {ProjectsPreviewData.map((project) => (
        <section key={project.id} className="project">
          <img
            src={project.img}
            alt={project.name}
            className="project-img drop-shadow-2xl"
          ></img>
          <section className="project-description">
            <p className="text-2xl font-semibold text-blue-400 drop-shadow-sm">
              {project.src ? (
                <a href={project.src} target="_blank" rel="noreferrer">
                  {project.name}
                </a>
              ) : (
                <p>{project.name}</p>
              )}
            </p>
            <p className="white-text p-tag">{project.description}</p>
            <section className="project-meta-stack ">
              {project.stack.map((stackName, index) => (
                <p key={index}>{stackName}</p>
              ))}
            </section>
            <section className="project-links">
              {project.src ? (
                <a href={project.src} target="_blank" rel="noreferrer">
                  <img src={ExternalLink} alt="External link icon"></img>
                </a>
              ) : (
                ''
              )}
              {project.source ? (
                <a href={project.source} target="_blank" rel="noreferrer">
                  <img src={GithubRepo} alt="Github icon"></img>
                </a>
              ) : (
                ''
              )}
            </section>
          </section>
        </section>
      ))}
    </section>
  );
};

export default Projects;

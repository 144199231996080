const Languages = () => {
  return (
    <section className="languages">
      <h2 className="title-font  pink-text h2-tag">Languages</h2>
      <section>
        <section>
          <div >
            <h3>English</h3>
            <p>Professional working proficiency</p>
          </div>
          <div className="mt-10">
            <h3>Amharic</h3>
            <p>Native or bilingual proficiency</p>
          </div>
        </section>
      </section>
    </section>
  );
};

export default Languages;

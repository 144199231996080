import React from 'react';

const Stack = () => {
  return (
    <React.Fragment>
      <h3 className="title-font white-text ">
        Here are a few tools & technologies I’ve been working with:
      </h3>
      <ol className="stack-section grid grid-cols-4 space-y-1">
        <li>React</li>
        <li>Angular</li>
        <li>Chrome Extension</li>
        <li>Typescript</li>
        <li>Redux</li>
        <li>NGXS</li>
        <li>NgRx</li>
        <li>Mobx</li>
        <li>Tailwind</li>
        <li>Jest</li>
        <li>Karma</li>
        <li>Websocket</li>
        <li>Storybook</li>
        <li>Nx</li>
        <li>Node</li>
        <li>PixiJs</li>
        <li>ThreeJs</li>
        <li>Canvas</li>
        <li>D3Js</li>
        <li>RxJs</li>
        <li>Ant Design</li>
        <li>NextJs</li>
        <li>Webflow</li>
        <li>Wordpress</li>
        <li>Docker</li>
        <li>Postgres</li>
        <li>AWS</li>
        <li>HTML5</li>
        <li>CSS3</li>
        <li>SCSS/SASS</li>
        <li>JavaScript ES6+</li>
        <li>RESTful API</li>
        <li>Github</li>
        <li>Git</li>
        <li>Figma</li>
        <li>Visual Studio Code</li>
      </ol>
    </React.Fragment>
  );
};
export default Stack;

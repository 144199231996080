import { Footer } from '../../components/index';
import Certifications from './Certifications';
import Languages from './Languages';
import Stack from './Stack';
import Education from './Education';
import WorkExperience from './WorkExperience';
import AboutMe from './AboutMe';
import ScrollButton from '../../helpers/ScrollToTop.js';
import React from 'react';

const About = () => {
  document.title = 'About | Abel Adane';
  return (
    <article className="about-main">
      <AboutMe />
      <Stack />
      <WorkExperience />
      <Education />
      <Languages />
      {/* <Certifications /> */}
      {/* <ScrollButton > */}
      {/* <Footer /> */}
    </article>
  );
};

export default About;

const ExperienceData = [
  {
    id: 1,
    jobtitle: 'Frontend Team Lead',
    companyname: 'Gebeya',
    worktype: 'Contract',
    duration: 'August 1 - now',
    location: 'Addis Ababa, Ethiopia',
    description:
      'Hire freelancers or permanent staff from our talent pool to work remotely or on-site. Gebeya Inc. is a Pan-African online Freelance Talent Marketplace headquartered in Addis Ababa, Ethiopia. The company aims to become an active contributor to Africa’s digital transformation and build a booming Africa through leveraging continental and global job opportunities for African talent. Gebeya’s network of highly skilled, certified, and multilingual African talent on a digital platform services clients across the globe. Gebeya’s vision is of an Africa equipped with a competitive skilled workforce, and a greater number of innovative startups that will drive digital transformation.				',
    responsibility: [
      `Ensuring that the team follows coding standards and best practices.`,
      `Promoting code reviews and unit testing.`,
      `Keeping the team up-to-date on the latest technologies.`,
      `Managing the team's workload and ensuring that they are not overworked.`,
      `Providing feedback to team members on their performance.`,
      `Helping team members to develop their skills and knowledge.`,
      `Develop responsive and functional UI components`,
      `Monitor basic system performance and logs using PM2`,
      `Onboard new team members to get familiarized with the tech stack, code of conduct, and application architecture.`,
    ],
  },
  {
    id: 2,
    jobtitle: 'Senior Frontend Developer',
    companyname: 'Gebeya',
    worktype: 'Contract',
    duration: 'June - 15 2023 - July 31 2023',
    location: 'Addis Ababa, Ethiopia',
    description:
      'Mesirat is a 5 year, multi-phase program to create opportunities for 1 million highly skilled workers through creating, supporting, and nurturing 100 multi-sided gig and professional marketplaces (GPMs) in Ethiopia.',
    responsibility: [
      `Develop and implement software solutions that enforce technical standards based on industry best practices`,
      `Writing code that is well-organized, efficient, and secure.`,
      `Collaborating with other developers, designers, and stakeholders to ensure that projects are completed on time and within budget.`,
      `Communicating with stakeholders, including the team, management, and customers.`,
      `Maintaining and optimizing existing UIs.`,
      `Keeping up-to-date with the latest frontend technologies.`,
      `Mentoring and training junior developers.`,
    ],
  },
  {
    id: 3,
    jobtitle: 'Associate Technical Lead',
    companyname: 'Excellerent Solutions',
    worktype: 'Full-time',
    duration: 'July 2022 - June 2023',
    location: 'Pittsburgh, Pennsylvania, USA (Remote)',
    description: '',
    responsibility: [
      `The technical direction of the team, including setting technical standards, reviewing code, and mentoring team members.`,
      `Managing the team's projects, including setting deadlines, tracking progress, and resolving issues.`,
      `Leading and motivating the team, providing feedback, and resolving conflicts.`,
      `Communicating with stakeholders, including the team, management, and customers.`,
      `Keeping up-to-date on the latest technologies and sharing their knowledge with the team.`,
      `Hiring and onboarding new team members`,
      `Conducting performance reviews`,
      `Developing and implementing team processes`,
      `Representing the team to management and other stakeholders`,
    ],
  },

  {
    id: 4,
    jobtitle: 'Senior Software Developer',
    companyname: 'Excellerent Solutions',
    worktype: 'Full-time',
    duration: 'July 2020 - July 2022',
    location: 'Pittsburgh, Pennsylvania, USA (Remote)',
    description:
      'As a Software developer, I lead multiple successful projects. I was responsible for coordinating, designing, and implementing the projects using multiple technologies. The technologies range from React Js, Angular, NestJs, Figma, and more. I was able to implement Agile methodology to make sure we were on time and can make adequate changes to the requirement given by the product owner',
    responsibility: [
      `Develop and implement software solutions that enforce technical standards based on industry best practices`,
      `Manage complete software development life cycle of complex web-based applications`,
      `Develop responsive and functional UI components with React(TypeScript), Redux, Ant Design and TailwindCSS`,
      `Test react components using React testing library`,
      `Monitor basic system performance and logs using PM2`,
      `Onboard new team members to get familiarized with the tech stack, code of conduct, and application architecture`,
    ],
  },
  {
    id: 5,
    jobtitle: 'Senior Software Developer',
    companyname: 'CyberSoft',
    worktype: 'Part-time',
    duration: 'Feb 2018 - July 2020',
    location: 'Addis Ababa, Ethiopia',
    description: '',
    responsibility: [
      `Planning architectural design and consistency of technology `,
      `utilized across the projects `,
      `Collaborate with other team members and stakeholders   `,
      `Develop new user-facing features `,
      `Build reusable code and libraries for future use`,
      `Ensure the technical feasibility of UI/UX designs`,
      `Initial project setup`,
      `Giving training and assisting junior teammates`,
    ],
  },
  {
    id: 6,
    jobtitle: 'Unity Developer',
    companyname: 'DG Electronics',
    worktype: 'Full-time',
    duration: 'Jul 2018 - Dec 2020',
    location: 'Addis Ababa, Ethiopia',
    responsibility: [
      `Plan and implement game functionality `,
      `Transform design specification into functional games`,
      `Communicate with other team members`,
      `Establish eective strategy and development pipeline`,
      `Design, build, and maintain ecient, reusable, and reliable code`,
      `Ensure the best performance, quality, and responsiveness of
applications and games`,
      `Identify process and application bottlenecks and bugs `,
      `Offer and implement solutions in a timely manner`,
    ],
  },
  {
    id: 7,
    jobtitle: 'Software Developer',
    companyname: "St. Mary's University",
    worktype: 'Contract',
    duration: 'April 2020 - June 2020',
    location: 'Addis Ababa, Ethiopia',
    responsibility: [
      `Develop new user-facing features`,
      `Planning architectural design and consistency of technology utilized across the projects`,
      `Build reusable code and libraries for future use`,
    ],
  },
  {
    id: 8,
    jobtitle: 'Software Developer',
    companyname: "St. Mary's University",
    worktype: 'Full-time',
    duration: 'Oct 2016 - April 2018',
    location: 'Addis Ababa, Ethiopia',
    responsibility: [
      `Planning architectural design and consistency of technology utilized across the projects`,
      `Collaborate with other team members and stakeholders`,
      `Develop new user-facing features`,
      `Build reusable code and libraries for future use`,
      `Ensure the technical feasibility of UI/UX designs`,
      `Initial project setup`,
    ],
  },
];

const EducationData = [
  {
    name: 'Bahir Dar University',
    status: 'Bsc, Computer Science',
    year: '2012 - 2016',
  },
];
const CertificationData = [
  {
    name: 'HTML and CSS in depth',
    source: 'Meta',
    date: 'Issued Sept 2022 · No Expiration Date',
    creditUrl:
      'https://www.coursera.org/account/accomplishments/certificate/9X4535T5LVXG',
  },
];
export { ExperienceData, EducationData, CertificationData };
